@import url("https://fonts.googleapis.com/css2?family=Fira+Code&family=IBM+Plex+Sans:wght@600&family=Inconsolata&family=Roboto+Mono&family=Source+Code+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Coming+Soon&display=swap");

:root {
  --black-color: #1f1e1e;
  --white-color: #e8eae6;
  --red-color: #e5707e;
  --yellow-color: #dcdda4;
  --green-color: #a0c1b8;
  --blue-color: #a3ddcb;
  --orange-color: #e6b566;
  --purple-color: #9955bb;
  --light-grey-color: #bbbbbb;
}
/* ============ INDEX ============ */
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: var(--black-color);
}

h3 {
  margin-bottom: -2.5px;
}

/* ========= MAIN APP ========== */
* {
  font-family: "Roboto Mono";
}

/* .app {
  background-color: var(--black-color);
} */

.app-about-me {
  margin: auto;
  width: fit-content;
  justify-content: center;
}

.app-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.app-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 22px;
}

.app-windows {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* ============== ICONS ============= */

.button-svg {
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 25px;
  right: 25px;
  color: grey;
  opacity: 0.5;
  font-size: 12px;
  text-decoration: none;
}
.button-svg:focus {
  outline: none;
}
.button-svg:hover {
  color: white;
}
.text-icon {
  vertical-align: middle;
  display: inline-block;
}
.svg-icon {
  vertical-align: middle;
  fill: gray;
  display: inline-block;
}
.svg-icon:hover {
  fill: white;
}

/* =========== NAV BAR ============ */

.navbar-items {
  height: 70px;
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 20px;
}

.navbar-logo {
  color: var(--white-color);
  justify-self: start;
  cursor: pointer;
  display: inline-block;
  margin: 0.75rem 0rem;
  font-size: 2rem;
  position: 70%;
  transition: all 0.3s ease;
}

.navbar-logo-software {
  margin: 0rem 0.25rem;
  font-size: 1.1rem;
  color: #e8eae6;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
}

.navbar-logo-engineer {
  margin: 0rem 0.25rem;
}

.first-name {
  color: var(--red-color);
  padding: 0rem 0.25rem;
}

.last-name {
  color: var(--yellow-color);
  padding: 0rem 0.25rem;
}

.nav-right-btn {
  text-align: center;
  float: right;
  color: var(--white-color);
  text-decoration: none;
  padding: 1.5rem 2rem;
  padding-left: 3.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.nav-left-btn {
  list-style: none;
  float: left;
  justify-content: end;
  color: var(--white-color);
  text-decoration: none;
  padding: 1.5rem 2rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.nav-left-btn b {
  display: none;
}

.nav-right-btn b {
  display: none;
}

.nav-left-btn:hover b {
  color: var(--red-color);
  transition: color 1s;
  display: block;
  padding: 0.1rem 0rem;
}

.nav-right-btn:hover b {
  color: var(--yellow-color);
  transition: color 1s;
  display: block;
  padding: 0.1rem 0rem;
}

@media screen and (max-width: 550px) {
  .navbar-logo {
    padding: 1.2rem 0rem;
    font-size: 1.3rem;
  }
  .navbar-logo-software {
    font-size: 0.8rem;
  }

  .nav-left-btn {
    font-size: 0.8rem;
    padding: 2.5rem 1.3rem;
  }
  .nav-right-btn {
    font-size: 0.8rem;
    padding: 2.5rem 1.3rem;
  }
}

@media screen and (max-width: 350px) {
  .navbar-logo {
    padding: 1.2rem 0rem;
    font-size: 1.1rem;
  }
  .navbar-logo-software {
    font-size: 0.6rem;
  }

  .nav-left-btn {
    font-size: 0.6rem;
    padding: 2.5rem 1rem;
  }
  .nav-right-btn {
    font-size: 0.6rem;
    padding: 2.5rem 1rem;
  }
}

/* ============== Projects =============== */

.links {
  display: flex;
}

.link {
  display: flex;
  margin-top: 10px;
  margin-right: 5px;
  padding: 6px;
  opacity: 0.7;
  cursor: pointer;
  border-radius: 5px;
  background: var(--green-color);
  color: var(--black-color);
  transition: all 0.15s ease-in;
  text-decoration: none;
}

.web-link {
  background: var(--orange-color);
}

.link:hover {
  opacity: 1;
  transform: translateX(-2px) translateY(-2px);
}
img {
  max-width: 100%;
  max-height: 100%;
}
.proj-img {
  width: 350px;
  height: 300px;
}

/* ============= EXPERIENCES ============== */
.experience-company {
  margin-bottom: -10px;
}
.experience-title {
  margin-bottom: -10px;
}
.experience-timeline {
  margin-bottom: 0;
  font-size: 11px;
  opacity: 0.8;
}

/* ============== COLORED TEXT ============= */

.yellow {
  color: var(--yellow-color);
}
.blue {
  color: var(--blue-color);
}
.orange {
  color: var(--orange-color);
}
.purple {
  color: var(--purple-color);
}
.red {
  color: var(--red-color);
}
.green {
  color: var(--green-color);
}

.grey {
  color: var(--light-grey-color);
}
